import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { BASE,UPDATE_BULK_EMAIL_URL, DELETE_BULK_EMAIL_URL, GET_BULK_EMAILS_URL,POST_BULK_EMAIL_URL,SAVE_BULK_EMAIL_URL,POST_PARTNER_BULK_EMAIL_URL } from "../../API/APIendpoint";
import EmailItem from "./EmailItem";
import EmailEdit from "./EmailEdit";
import "w3-css/w3.css";
import image from '../../assets/img/NarfoLogo.png';
import { toast } from 'react-toastify';
import EmailNew from './EmailNew';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,    
  },
});

toast.configure();

class EmailsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailList:[],
      loading: false,
      selectedEmail: null,
      partnerEmail:null,
      selectedEmailEdit:null,
      isNewEmail:false,
      isEditEmail:false,
      partnerList: [],
      open: false,
      age: '',
      selectedStore: '',
      emailLoading:false,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    Axios.get(BASE + GET_BULK_EMAILS_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.setState({ loading: false,emailList: res.data.reverse()});
    });

    //Get all partners
    Axios({
      url: `${BASE}api/Stores`,
      method: "get",
      headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
  }).then(res => {
      this.setState({
          partnerList: res.data
      })
  })
  }

  handleClickOpen = (email) => {
    this.setState({ open: true ,partnerEmail: email});
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSendPartnerEmail = () => {

    this.setState({emailLoading:true});

    //Send the email here 
    let newEmail = {};
    var email = this.state.partnerEmail

    newEmail = {
      "createBy": localStorage.getItem("memNo"),
      "recipient": "",
      "subject": email.subject,
      "body": email.body,
      "progress": 0,
      "id": email.id,
      "partnerId": this.state.selectedStore
    }

    if(newEmail.subject.length < 2 || newEmail.body < 2)
      {
        toast.error("Error sending message.");
        return;
      }

    Axios.post(BASE + POST_PARTNER_BULK_EMAIL_URL ,newEmail,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
        this.setState({open:false,selectedStore:null,emailLoading:false});
        toast.success("Email Sent");
      })
      .catch(error =>{
        console.log("Error sending bulk email",error);
        toast.error("Error sending bulk email." + error.message);
      });
    this.setState({ open: false , selectedStore:null,emailLoading:false});

  };

  handleChange = (event) => {
    this.setState({ selectedStore: event.target.value });
  };

  addTestEmail = (email) => {
    //Submit 
    let newEmail = {};

    newEmail = {
      "createBy": localStorage.getItem("memNo"),
      "subject": email.subject,
      "body": email.body,
      "recipient": localStorage.getItem("memNo")
    }
    
    if(newEmail.subject.length < 2 || newEmail.body < 2)
      {
        toast.error("Error sending message.");
        return;
      }
    Axios.post(BASE + POST_BULK_EMAIL_URL ,newEmail,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
        this.setState({open:false,isNewEmail:false});
        toast.success("Test email sent Email Sent");
      })
      .catch(error =>{
        console.log("Error sending bulk email",error);
        toast.error("Error sending bulk email." + error.message);
      });
  };

  handleSubmit = (email) => {
    //Submit 
    let newEmail = {};

    
    newEmail = {
      "createBy": localStorage.getItem("memNo"),
      "recipient": "",
      "subject": email.subject,
      "body": email.body,
      "progress": 0,
      "id": email.id
    }

    if(newEmail.subject.length < 2 || newEmail.body < 2)
      {
        toast.error("Error sending message.");
        return;
      }

    Axios.post(BASE + POST_BULK_EMAIL_URL ,newEmail,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
        this.setState({open:false,isNewEmail:false});
        toast.success("Email Sent");
      })
      .catch(error =>{
        console.log("Error sending bulk email",error);
        toast.error("Error sending bulk email." + error.message);
      });
  };
  
  handleSaveSubmit = (email) => {
    //Submit 
    let newEmail = {};

    newEmail = {
      "createBy": localStorage.getItem("memNo"),
      "recipient": "",
      "subject": email.subject,
      "body": email.body,
      "progress": 0
    };

    if(newEmail.subject.length < 2 || newEmail.body < 2)
      {
        toast.error("Error saving email.");
        return;
      }

    Axios.post(BASE + SAVE_BULK_EMAIL_URL ,newEmail,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
        let emails = [res.data,...this.state.emailList]
        this.setState({open:false,emailList: emails,isNewEmail:false});
        toast.success("Email saved");
      })
      .catch(error =>{
        console.log("Error sending bulk email",error);
        toast.error("Error sending bulk email." + error.message);
      });
  };

  handleUpdateSubmit = (email) => {
    //Submit 
    let newEmail = {};

    newEmail = {
      "createBy": localStorage.getItem("memNo"),
      "subject": email.subject,
      "body": email.body,
      "id": email.id
    };

    if(newEmail.subject.length < 2 || newEmail.body < 2)
      {
        toast.error("Error saving email.");
        return;
      }

    Axios.post(BASE + UPDATE_BULK_EMAIL_URL ,newEmail,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
          
          let filteredEmails = this.state.emailList.filter(e => e.id != email.id);
          let emails = [res.data,...filteredEmails];
          
        this.setState({open:false,emailList: emails,isNewEmail:false,isEditEmail:false});
        toast.success("Email updated");
      })
      .catch(error =>{
        console.log("Error updating bulk email",error);
        toast.error("Error updating bulk email." + error.message);
      });
  };

  selectedEmail = (email) =>{
    this.setState({selectedEmail:email});
  };

  backFunction = () =>{
    this.setState({selectedEmail:null,isNewEmail:false, isEditEmail:false,selectedEmailEdit:null});
  };
  
  handleDelete = (email) => {
    
    Axios.post(BASE + DELETE_BULK_EMAIL_URL + "?id="+ email.id,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        let tempList = this.state.emailList;
        const index = tempList.findIndex(key => key.id === email.id)
        tempList.splice(index,1);
        this.setState({emailList:tempList,selectedEmail:null});
      })
      .catch(error =>{
        console.log("Error deleting bulk email",error);
        this.setState({ loading: false});
        toast.error("Error deleting bulk email." + error.message);
      });
  };

  render() {
    const { classes } = this.props;
    const { open, age } = this.state;
    
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">Loading bulk emails. Please wait ...</p>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row pt-4">
          <div className="col-12 h2 pl-0">Bulk Emails </div>
          {!this.state.selectedEmail && !this.state.isNewEmail && !this.state.isEditEmail && <div className="col-12 pt-1" align="right">
            <button onClick={()=>{this.setState({isNewEmail:true})}} className="btn-success">Create New</button>
          </div>}

          {this.state.isNewEmail && <EmailNew backFunction={this.backFunction} handleSubmit={this.handleSaveSubmit}/>}
        </div>
        { !this.state.selectedEmail && !this.state.isNewEmail && !this.state.isEditEmail && this.state.emailList?.map((email, index) => {
          return (
            <div  key={index}>

                <div className="row border p-2 mt-3">
                <div className="col-12">
                    <div className="row">
                    <div className="col-8 pt-3">
                        <div className="row" style={{marginLeft:"20px"}}>
                        <div className="col-12" align="left">
                          <div className="row">
                           <h3 onClick={()=>this.setState({selectedEmail:email})} style={{font:'bold',cursor: "pointer",fontWeight: 900, marginRight:'5px'}}> 
                           {email.subject}</h3> <BorderColorOutlinedIcon style={{cursor: "pointer"}}  onClick={()=>{this.setState({selectedEmailEdit:email,isEditEmail:true})}} color="primary" fontSize="medium"/>
                           
                          </div>
                          <div className="row" onClick={()=>this.setState({selectedEmail:email})} style={{cursor: "pointer"}}>
                            <span style={{font:'caption',fontWeight: 200}}> Date sent : {new Date(email.dateSent).toLocaleString()} </span>
                          </div>
                          <div className="row" onClick={()=>this.setState({selectedEmail:email})} style={{cursor: "pointer"}}>
                            <span style={{font:'caption',fontWeight: 200,marginTop:'10px'}}> Sent ({email.progress}%)</span>
                          </div>
                          <div className="row" onClick={()=>this.setState({selectedEmail:email})} style={{cursor: "pointer"}}>
                            <span style={{font:'caption',fontWeight: 200,marginTop:'10px'}}> Sent to <strong>{email.noOfSentEmails}</strong> members</span>
                          </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-4 pt-4" align="right">
                      
                        <button onClick={()=> this.addTestEmail(email)} style={{marginRight:'10px'}} className="btn-info">Test Email</button>
                        <button onClick={()=> this.handleSubmit(email)} style={{marginRight:'10px'}} className="btn-warning">All Members</button>
                        <button onClick={()=>{this.handleDelete(email)}} className="btn-danger" style={{marginRight:"5px"}}>delete</button>
                    </div>
                    <div className="col-12 -pt-4" align="right">
                    <button className="btn-success" onClick={()=>this.handleClickOpen(email)}>Partner Members</button>
                    
                    <Dialog open={open} maxWidth="xs" fullWidth onClose={this.handleClose} style={{backgroundColor:'white'}}>
                      <DialogTitle>Select a Partner to send <b> {this.state.partnerEmail?.subject}</b> email</DialogTitle>
                      <DialogContent>
                        <form className={classes.container}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="store-select-label" >Partner list</InputLabel>
                            <Select
                              labelId="store-select-label"
                              value={this.state.selectedStore}
                              onChange={this.handleChange}
                              input={<Input />}
                            >
                              {this.state.partnerList.map((store) => (
                                <MenuItem key={store.storeId} value={store.storeId}>
                                  {store.store}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </form>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                          Cancel
                        </Button>
                        <>
                        {this.state.emailLoading ?
                        <CircularProgress color="secondary" size={20} />
                      : 
                      <Button
                      onClick={()=>{this.handleSendPartnerEmail(email)}} 
                      color="primary"
                      disabled={!this.state.selectedStore}
                    >
                      Send Email
                    </Button>
                      }
                        </>
                      </DialogActions>
                    </Dialog>
                    </div>
                    </div>
                </div>
                </div>

            </div>
          );
        })}
        {this.state.selectedEmail && <EmailItem backFunction={this.backFunction} handleDelete={this.handleDelete} email = {this.state.selectedEmail}/>}

        {this.state.isEditEmail && <EmailEdit backFunction={this.backFunction} handleUpdateSubmit={this.handleUpdateSubmit} bulkEmail  = {this.state.selectedEmailEdit}/>}

        
      </div>
    );
  }
}

export default withStyles(styles)(EmailsHome);